// These entitlements are specific to the AuthAPI infrastructure, corresponding to entitlements defined in
// https://dev.azure.com/samcado/VMSNext/_git/AuthorizationAPI?path=/Service/NuGet/Entitlements/Grouping/AuthEntitlementGroups.cs
// NOTE: Names here must match the names from the above file exactly. If adding a new entitlement, make sure it exists there and
// is bootstrapped to the db correctly before referencing it here
const AuthEntitlements = {
    Internal: { InternalAdministration: 'Internal Administration' },
    Entitlement: {
        GetEntitlement: 'Get Entitlement',
        ListEntitlements: 'List Entitlements',
        CreateEntitlements: 'Create Entitlements',
        UpdateEntitlements: 'Update Entitlements',
        DeleteEntitlements: 'Delete Entitlements',
    },
    Role: {
        GetRole: 'Get Role',
        ListRoles: 'List Roles',
        CreateRoles: 'Create Roles',
        UpdateRoles: 'Update Roles',
        DeleteRoles: 'Delete Roles',
        AssignEntitlements: 'Assign Entitlements to Roles',
    },
    Token: {
        GetTokenUser: 'Get Token User',
        ListTokenUsers: 'List Token Users',
        CreateTokenUsers: 'Create Token Users',
        UpdateTokenUsers: 'Update Token Users',
        DeleteTokenUsers: 'Delete Token Users',
        DisableTokenUsers: 'Enable or Disable Token Users',
        ExpireKeys: 'Expire Token User Keys',
        ResetKeys: 'Reset Token User Keys',
        AssignRoles: 'Assign Roles to Token Users',
        AssignEntitlements: 'Assign Entitlements to Token Users',
    },
    User: {
        GetUser: 'Get User',
        ListUsers: 'List Users',
        CreateUsers: 'Create Users',
        UpdateUsers: 'Update Users',
        DeleteUsers: 'Delete Users',
        DisableUsers: 'Enable or Disable Users',
        AssignRoles: 'Assign Roles to Users',
        AssignEntitlements: 'Assign Entitlements to Users',
        RequestUsers: 'Request Users',
    },
};

// These entitlements are specific to the VMSNext infrastructure, corresponding to entitlements defined in
// https://dev.azure.com/samcado/VMSNext/_git/AuthorizationAPI?path=/Service/NuGet/Entitlements/Grouping/VMSNextEntitlementGroups.cs
// NOTE: Names here must match the names from the above file exactly. If adding a new entitlement, make sure it exists there and
// is bootstrapped to the db correctly before referencing it here
const VMSNextEntitlements = {
    ConfigurationManagement: {
        ManageCustomFields: 'Manage Custom Fields',
        ManageDomainConfigurations: 'Manage Domain Configurations',
        ManagePicklists: 'Manage Picklists',
    },
    DataAccess: {
        AccessAllCompanyData: 'Access All Company Data',
        AccessAllPortfolioData: 'Access All Portfolio Data',
        AccessLimitedCompanyData: 'Access Limited Company Data',
        ChangeWorkflow: 'Domain:VALU_Valuation:ChangeWorkflow',
        GenerateDomainAddEntitlementName: (domainId: string): string => `Domain:${domainId}:Add`,
        GenerateDomainDeleteEntitlementName: (domainId: string): string => `Domain:${domainId}:Delete`,
        GenerateDomainUpdateEntitlementName: (domainId: string): string => `Domain:${domainId}:Update`,
        ListDocumentTypes: 'List Document Types',
        ValuationViewDebtEquity: 'Valuation:ViewDebtEquity',
    },
    DocumentType: {
        GenerateViewUpdateDownloadEntitlement: (docTypeName: string): string =>
            `DocType:${docTypeName}:View/Upload/Download`,
    },
    FormViewLink: {
        GenerateFormViewLinkEntitlementName: (formViewId: string, linkTarget: string): string =>
            `FormView:${formViewId}:LinkedModal:${linkTarget}`,
    },
    ValuationWorkflow: {
        ValuationWorkflowSendNotificationReminder: 'ValuationWorkflow:SendNotificationReminder',
        ValuationWorkflowViewWorkflowTemplate: 'ValuationWorkflow:ViewWorkflowTemplate',
    },
    ViewSet: {
        GenerateViewSeteEntitlementName: (domainId: string, viewSetId: string): string =>
            `${domainId}/ViewSet/${viewSetId}`,
    },
    ViewSetAction: {
        GenerateViewSetActionEntitlementName: (
            domainId: string,
            viewSetId: string,
            actionDisplayText: string,
        ): string => `${domainId}/ViewSet/${viewSetId}/ViewSetAction/${actionDisplayText}`,
    },
    ViewSetTab: {
        GenerateViewSetTabEntitlementName: (domainId: string, viewSetId: string, viewSetTabId: string): string =>
            `${domainId}/ViewSet/${viewSetId}/ViewSetTab/${viewSetTabId}`,
    },
    Reporting: {
        ManageReports: 'Manage Reports',
    },
};

export const Entitlements = {
    ...AuthEntitlements,
    ...VMSNextEntitlements,
};

export default Entitlements;
